import React, { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonLabel, IonSelect, IonSelectOption, IonRadioGroup, IonRadio, IonItem, IonInput, IonCheckbox, IonText, isPlatform } from '@ionic/react';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { states, today, isMobile, formatDateToString, validateDate, occupationCategories, occupations, countries } from '../../helpers/utils';
import {  convertStringDateToDate } from '../../helpers/sharedUtils';
import InputMask from 'react-input-mask';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { saveWelcomePage, saveOwnerQuestionnairePage } from '../../helpers/calloutHelpers';
import { InitSessionApp } from '../../helpers/sessionInterfaces';
import {connect } from 'react-redux';
import * as actions from '../../store/actions';

interface OwnerInfoPage extends InitSessionApp {
    welcomePageFields: welcomePageParameters,
    formData?: {data?: Forms.applicantOwnerQuestionnaireForm},
    setShowSpinner: Function,
    setWelcomePageFields: (payload: Partial<welcomePageParameters>) => void,
}

const minIssueDate = `${String(new Date().getMonth()+ 1)}/${String(new Date().getDate())}/${String(new Date().getFullYear()-100)}`;
const maxExpirationDate = `${String(new Date().getMonth()+ 1)}/${String(new Date().getDate())}/${String(new Date().getFullYear()+100)}`;

const OwnerQuestionnaire: React.FC<OwnerInfoPage> = ({sessionId, updateMenuItem, formRef, setShowSpinner, welcomePageFields, setWelcomePageFields, formData,  handleNavigation, setErrorMessage, setShowErrorToast}) => {
    const getDefaultValues = ()=>{
        let defaultForm: Forms.applicantOwnerQuestionnaireForm ={
            household_income: '',
            net_worth: '',
            projected_retirement: '',
            employment: '',
            occupation_category: '',
            occupation: '',
            employer_name: '',
            employer_street_address: '',
            employer_apt_suite_building: '',
            employer_city: '',
            employer_state: '',
            employer_zip: '',
            primary_income: '',
            former_occupation_category: '',
            former_occupation: '',
            identification_type: '',
            state_of_issuance: '',
            country_of_issuance: '',
            identity_id_number: '',
            id_issue_date: '',
            id_expiration_date: '',
            account_purpose: '',
            initial_funds_retirement_funds: false,
            initial_funds_transfer: false,
            initial_funds_rollover: false,
            initial_funds_employment_wages: false,
            initial_funds_investments: false,
            initial_funds_inheritance_trust: false,
            initial_funds_other: false,
            initial_funds_other_detail: '',
            ongoing_funds_retirement_funds: false,
            ongoing_funds_transfer: false,
            ongoing_funds_rollover: false,
            ongoing_funds_employment_wages: false,
            ongoing_funds_investments: false,
            ongoing_funds_inheritance_trust: false,
            ongoing_funds_other: false,
            ongoing_funds_other_detail: '',
            ...formData?.data
        }
        return defaultForm
    }

    const {control, handleSubmit, errors, formState, setValue} = useForm<Forms.applicantOwnerQuestionnaireForm>({
        mode: "onChange",
        defaultValues: getDefaultValues()
    });

    const employment = useWatch({
        name:'employment',
        control: control
    })

    const identification_type = useWatch({
        name:'identification_type',
        control: control
    })

    const initial_funds_other = useWatch({
        name:'initial_funds_other',
        control: control
    })

    const ongoing_funds_other = useWatch({
        name:'ongoing_funds_other',
        control: control
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { isDirty, submitCount } = formState;

    const [initialFundsError, setInitialFundsError] = useState(false);

    const [ongoingFundsError, setOngoingFundsError] = useState(false);

    const save = async (form:Forms.applicantOwnerQuestionnaireForm) => {
        setShowSpinner(true);
        try {
            await saveWelcomePage(sessionId, welcomePageFields);
            await saveOwnerQuestionnairePage(sessionId, form);
            initialFundsValidationCheck(form);
            ongoingFundsValidationCheck(form);
            updateMenuItem('is_owner_questionnaire_page_valid', true, sessionId);
            handleNavigation({status: 'Success'});
        } catch (err) {
            setShowSpinner(false);
            handleNavigation({status: 'Error'});
        }
    }

    const showError = (fieldName: string) => {
        let errorsArr = (Object.keys(errors));
        let className = '';
        if ((submitCount > 0) && errorsArr.includes(fieldName)) {
            className = 'danger';
        }
        return className;
    };

    const handleIsSelfEmployedChange = (e:any) => {
        setWelcomePageFields({is_self_employed: e.target.value});
    }

    const handleUnitedStates = (e:any) => {
        setValue("identification_type", e.target.value);
        if (e.target.value === 'passport') {
            setValue("country_of_issuance", "United States");
        } else if (e.target.value !== 'passport') {
            setValue("country_of_issuance", '');
        }
    }

    const onFormInvalid = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    const initialFundsValidationCheck = (form:Forms.applicantOwnerQuestionnaireForm) => {
        if ((submitCount > 0) && form.initial_funds_retirement_funds !== true &&
            form.initial_funds_transfer !== true &&
            form.initial_funds_employment_wages !== true &&
            form.initial_funds_investments !== true &&
            form.initial_funds_inheritance_trust !== true &&
            form.initial_funds_rollover !== true &&
            form.initial_funds_other !== true) {
                setInitialFundsError(true);
                throw new Error('Must select an initial source of funds');
            } else {
                setInitialFundsError(false);
            }
    };

    const ongoingFundsValidationCheck = (form:Forms.applicantOwnerQuestionnaireForm) => {
        if ((submitCount > 0) && form.ongoing_funds_retirement_funds !== true &&
            form.ongoing_funds_transfer !== true &&
            form.ongoing_funds_employment_wages !== true &&
            form.ongoing_funds_investments !== true &&
            form.ongoing_funds_inheritance_trust !== true &&
            form.ongoing_funds_rollover !== true &&
            form.ongoing_funds_other !== true) {
                setOngoingFundsError(true);
                throw new Error('Must select an ongoing source of funds');
            } else {
                setOngoingFundsError(false);
            }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(save,onFormInvalid)} className='ion-padding'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IonGrid>
                <IonRow className="well">
                    <IonCol>
                        <strong><p>The following questions are asked to help Equity Trust better understand you and tailor our service accordingly.</p>
                        <p>Per our privacy policy, Equity Trust does not share or sell your personal information.</p></strong>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <strong><p>EMPLOYMENT AND OCCUPATION</p></strong>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                        <IonLabel>Employment Type *</IonLabel>
                        <IonItem className={showError('employment')}>
                            <Controller 
                                name={'employment'}
                                control={control}
                                rules={{required:true}}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="employed">Employed</IonSelectOption>
                                    <IonSelectOption value="unemployed">Unemployed</IonSelectOption>
                                    <IonSelectOption value="retired">Retired</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                        {errors.employment && <IonText color='danger'>You must select an employment option</IonText>}
                    </IonCol>
                </IonRow>
                {employment === 'employed' && (
                    <>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Occupation Category *</IonLabel>
                                <IonItem className={showError('occupation_category')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='occupation_category' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {occupationCategories.map((occupationCategory, index) => <IonSelectOption value={occupationCategory} key={index}>{occupationCategory}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true}}/> :
                                  <Controller name='occupation_category' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('occupation_category', value)
                                    }} 
                                    options={occupationCategories} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'Occupation Category'};
                                        let innerInputProps = {...params.inputProps, maxLength: "20"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true
                                    }} />
                                }
                            </IonItem>
                            {errors.occupation_category && <IonText color='danger'>You must select an occupation category option</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Occupation *</IonLabel>
                                <IonItem className={showError('occupation')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='occupation' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {occupations.map((occupation, index) => <IonSelectOption value={occupation} key={index}>{occupation}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true}}/> :
                                  <Controller name='occupation' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('occupation', value)
                                    }} 
                                    options={occupations} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'Occupation'};
                                        let innerInputProps = {...params.inputProps, maxLength: "20"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true
                                    }} />
                                }
                            </IonItem>
                            {errors.occupation && <IonText color='danger'>You must select an occupation option</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Employer Name *</IonLabel>
                                <IonItem className={showError('employer_name')}> 
                                  <Controller name='employer_name' control={control} render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='Employer Name' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 255}} />
                                </IonItem>
                                {errors.employer_name && <IonText color='danger'>You must enter an employer name</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <strong><p>Employer Address:</p></strong>
                        </IonRow>
                        <IonRow>
                        <IonCol size="4" sizeMd="4" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Street Address *
                            </IonLabel>
                            <IonItem className={showError('employer_street_address')}>
                                  <Controller name='employer_street_address' control={control} render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='Street' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 255}} />
                            </IonItem>
                            {errors.employer_street_address && <IonText color='danger'>You must enter a street address</IonText>}
                        </IonCol>
                        <IonCol size="4" sizeMd="4" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Apt / Suite / Building (Optional)
                            </IonLabel>
                            <IonItem> 
                                  <Controller name='employer_apt_suite_building' control={control} render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='Apt / Suite / Building' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{maxLength: 40}} />
                            </IonItem>
                        </IonCol>
                        <IonCol size="4" sizeMd="4" sizeSm="12" sizeXs="12">
                            <IonLabel>
                            City *
                            </IonLabel>
                            <IonItem className={showError('employer_city')}>
                                <Controller name='employer_city' control={control}  render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='City' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 255}} />
                            </IonItem>
                            {errors.employer_street_address && <IonText color='danger'>You must enter a city</IonText>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                State * 
                            </IonLabel>
                            <IonItem className={showError('employer_state')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='employer_state' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true, validate: (val) => {return /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1)}}}/> :
                                  <Controller name='employer_state' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('employer_state', value)
                                    }} 
                                    options={states} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                        let innerInputProps = {...params.inputProps, maxLength: "2"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{required: true, validate: (val) => {return /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1)}}} />
                                }
                            </IonItem>
                            {errors.employer_state && <IonText color='danger'>You must select a state</IonText>}
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Zip *
                            </IonLabel>
                            <IonItem className={showError('employer_zip')}>
                                <Controller name='employer_zip' control={control} as={
                                     <InputMask maskChar='' mask='99999' placeholder='Zip' name='legal_zip' className='input-mask'/>
                                }  rules={{
                                    required: true,
                                    pattern:  /^[0-9]{5}(?:-[0-9]{4})?/
                                }}/>
                            </IonItem>
                            {errors.employer_zip && <IonText color='danger'>You must enter a zip</IonText>}
                        </IonCol>
                    </IonRow>
                    </>
                )}
                {(employment === 'unemployed' || employment === 'retired') && (
                    <>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Primary Source of Income *</IonLabel>
                                <IonItem className={showError('primary_income')}>
                                    <Controller
                                        name={'primary_income'}
                                        control={control}
                                        rules={{required:true}}
                                        render={({value, name, onBlur, onChange})=>
                                        <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                            <IonSelectOption value="social_security">Social Security</IonSelectOption>
                                            <IonSelectOption value="pension">Pension</IonSelectOption>
                                            <IonSelectOption value="disability_benefits">Disability Benefits</IonSelectOption>
                                            <IonSelectOption value="investments">Investments</IonSelectOption>
                                            <IonSelectOption value="unemployment_benefits">Unemployment Benefits</IonSelectOption>
                                            <IonSelectOption value="public_assistance">Public Assistance</IonSelectOption>
                                            <IonSelectOption value="retirement_accounts_savings">Retirement Accounts/Savings</IonSelectOption>
                                            <IonSelectOption value="inheritance_trust">Inheritance/Trust</IonSelectOption>
                                            <IonSelectOption value="alimony_child_support">Alimony or Child Support</IonSelectOption>
                                        </IonSelect>}/>
                                </IonItem>
                                {errors.primary_income && <IonText color='danger'>You must enter a primary income source</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Former or Most Recent Occupation Category *</IonLabel>
                                <IonItem className={showError('former_occupation_category')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='former_occupation_category' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {occupationCategories.map((occupationCategory, index) => <IonSelectOption value={occupationCategory} key={index}>{occupationCategory}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true}}/> :
                                  <Controller name='former_occupation_category' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('former_occupation_category', value)
                                    }} 
                                    options={occupationCategories} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'Former Occupation Category'};
                                        let innerInputProps = {...params.inputProps, maxLength: "20"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true
                                    }} />
                                }
                                </IonItem>
                                {errors.former_occupation_category && <IonText color='danger'>You must select a former occupation category option</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Former or Most Recent Occupation *</IonLabel>
                                <IonItem className={showError('former_occupation')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='former_occupation' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {occupations.map((occupation, index) => <IonSelectOption value={occupation} key={index}>{occupation}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true}}/> :
                                  <Controller name='former_occupation' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('former_occupation', value)
                                    }}
                                    options={occupations} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'Former Occupation'};
                                        let innerInputProps = {...params.inputProps, maxLength: "20"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true
                                    }} />
                                }
                                </IonItem>
                                {errors.former_occupation && <IonText color='danger'>You must select a former occupation option</IonText>}
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow>
                    <strong><p>IDENTIFICATION</p></strong>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                        <IonLabel>Type of Identification *</IonLabel>
                        <IonItem className={showError('identification_type')}>
                            <Controller 
                                name={'identification_type'}
                                control={control}
                                rules={{required:true}}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={handleUnitedStates} onIonBlur={onBlur}>
                                    <IonSelectOption value="drivers_license">US Driver's License</IonSelectOption>
                                    <IonSelectOption value="passport">US Passport</IonSelectOption>
                                    <IonSelectOption value="state_id">US State ID</IonSelectOption>
                                    <IonSelectOption value="other_passport">Other Government-Issued Passport</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                        {errors.identification_type && <IonText color='danger'>You must select an identification type</IonText>}
                    </IonCol>
                </IonRow>
                {(identification_type === 'drivers_license' || identification_type === 'state_id') && (
                    <>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>State of Issuance *</IonLabel>
                                <IonItem className={showError('state_of_issuance')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='state_of_issuance' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {states.map((state, index) => <IonSelectOption value={state} key={index}>{state}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true, validate: (val) => {return /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1)}}}/> :
                                  <Controller name='state_of_issuance' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('state_of_issuance', value)
                                    }} 
                                    options={states} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'State'};
                                        let innerInputProps = {...params.inputProps, maxLength: "2"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true, validate: (val) => {return /[A-Za-z]{2}/.test(val) && (states.findIndex((state) => state === val?.toUpperCase()) !== -1)}
                                    }} />
                                }
                            </IonItem>
                            {errors.state_of_issuance && <IonText color='danger'>You must select a state of issuance</IonText>}
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(identification_type === 'passport' || identification_type === 'other_passport') && (
                    <>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>Country of Issuance *</IonLabel>
                                <IonItem className={showError('country_of_issuance')}>
                                {isMobile(isPlatform) ? 
                                <Controller name='country_of_issuance' control={control} render={({value, name, onBlur, onChange})=>
                                    <IonSelect name={name} interface='action-sheet' interfaceOptions={{cssClass: 'states-select', animated: false, mode: 'ios'}}  value={value} onIonBlur={onBlur} onIonChange={onChange}>
                                        {countries.map((country, index) => <IonSelectOption value={country} key={index}>{country}</IonSelectOption>)}
                                    </IonSelect>
                                } rules={{required:true, validate: (val) => {return /[A-Za-z]/.test(val) && (countries.findIndex((country) => country.toUpperCase() === val?.toUpperCase()) !== -1)}}}/> :
                                  <Controller name='country_of_issuance' control={control} render={({value, name})=>
                                    <Autocomplete componentName={name} value={value} id={name} 
                                    onChange={(event, value)=>{
                                        setValue('country_of_issuance', value)
                                    }} 
                                    options={countries} getOptionLabel={option => option} autoSelect={true} freeSolo={true} renderOption={option =>(
                                    <span>{option}</span>
                                    )} renderInput={params => {
                                        let newInputProps = {...params.InputProps, disableUnderline: true, placeholder: 'Country of Issuance'};
                                        let innerInputProps = {...params.inputProps, maxLength: "20"};
                                        let newParams = {...params, InputProps: newInputProps, inputProps: innerInputProps}; 
                                        return (
                                        <TextField type="text" {...newParams}/>
                                    )}} />} 
                                    rules={{
                                        required: true, validate: (val) => {return /[A-Za-z]/.test(val) && (countries.findIndex((country) => country.toUpperCase() === val?.toUpperCase()) !== -1)}   
                                    }} />
                                }
                                </IonItem>
                                {errors.country_of_issuance && <IonText color='danger'>You must select a country of issuance</IonText>}
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {identification_type !== '' && (
                    <>
                        <IonRow>
                            <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                                <IonLabel>ID Number *</IonLabel>
                                <IonItem className={showError('identity_id_number')}> 
                                  <Controller name='identity_id_number' control={control} render={({value, name, onBlur, onChange})=>
                                      <IonInput name={name} placeholder='ID Number' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                  } rules={{required:true, maxLength: 30}} />
                                </IonItem>
                                {errors.identity_id_number && <IonText color='danger'>You must enter an ID number</IonText>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Issue Date *
                            </IonLabel>
                            <IonItem className={showError('id_issue_date')}>
                                <Controller name='id_issue_date' control={control} render={({value, name})=>
                                        <KeyboardDatePicker
                                        name={name}
                                        className="date-width" disableFuture={true} margin='normal'  placeholder="mm/dd/yyyy" value={convertStringDateToDate(value)}  format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} onChange={(date)=>{
                                            if(date){
                                                setValue('id_issue_date', formatDateToString(date))
                                            }
                                        }}/>
                                    } 
                                    rules={{required:true, min: minIssueDate, max: today, validate: (val) => {
                                        if (typeof val === 'string') {
                                            val = new Date(val);
                                        }
                                        const maxDate = new Date(today);
                                        const minDate = new Date(minIssueDate); 
                                       
                                        return validateDate(val, minDate, maxDate); 
                                    }}}/>
                            </IonItem>
                            {errors.id_issue_date && <IonText color='danger'>You must enter a past date</IonText>}
                        </IonCol>
                        <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                            <IonLabel>
                                Expiration Date *
                            </IonLabel>
                            <IonItem className={showError('id_expiration_date')}>
                                <Controller name='id_expiration_date' control={control} render={({value, name})=>
                                        <KeyboardDatePicker
                                        name={name}
                                        className="date-width" disablePast={true} margin='normal'  placeholder="mm/dd/yyyy" value={convertStringDateToDate(value)}  format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} onChange={(date)=>{
                                            if(date){
                                                setValue('id_expiration_date', formatDateToString(date))
                                            }
                                        }}/>
                                    } 
                                    rules={{required:true, min: today, max: maxExpirationDate, validate: (val) => {
                                        if (typeof val === 'string') {
                                            val = new Date(val);
                                        }
                                        const maxDate = new Date(maxExpirationDate);
                                        const minDate = new Date(today); 
                                       
                                        return validateDate(val, minDate, maxDate); 
                                    }}}/>
                            </IonItem>
                            {errors.id_expiration_date && <IonText color='danger'>You must enter a future date</IonText>}
                        </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow>
                    <strong><p>FINANCIAL ACCOUNT INFORMATION</p></strong>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                        <IonLabel>What is the purpose of this account? *</IonLabel>
                        <IonItem className={showError('account_purpose')}>
                            <Controller 
                                name={'account_purpose'}
                                control={control}
                                rules={{required:true}}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="retirement">Retirement</IonSelectOption>
                                    <IonSelectOption value="wealth_investment">Wealth Accumulation / Investment</IonSelectOption>
                                    <IonSelectOption value="income">Current Income (Dividends and Interest Payments)</IonSelectOption>
                                    <IonSelectOption value="education">Education Planning</IonSelectOption>
                                    <IonSelectOption value="healthcare">Healthcare</IonSelectOption>
                                </IonSelect>}/>
                         </IonItem>
                         {errors.account_purpose && <IonText color='danger'>You must select an account purpose</IonText>}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                        <IonLabel>What best describes the initial source of funds for this account (check all that apply): *</IonLabel>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_retirement_funds' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_retirement_funds', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Retirement Funds
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_transfer' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_transfer', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Transfer
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_rollover' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_rollover', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Rollover
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_employment_wages' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_employment_wages', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Employment/Wages
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_investments' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_investments', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Investments
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_inheritance_trust' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_inheritance_trust', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Inheritance/Trust
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='initial_funds_other' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('initial_funds_other', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Other
                        </IonRow>
                        {initial_funds_other && (
                                <>
                                <IonRow>
                                    <IonCol>
                                        <IonItem className={showError('initial_funds_other_detail')}>
                                            <Controller name='initial_funds_other_detail' control={control} render={({value, name, onBlur, onChange})=>
                                                <IonInput name={name} placeholder='Please describe other initial sources of funds:' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                            } rules={{required:true, maxLength: 255}} />
                                        </IonItem>
                                        {errors.initial_funds_other_detail && <IonText color='danger'>You must enter additional details</IonText>}
                                    </IonCol>
                                </IonRow>
                                </>
                            )}
                    </IonCol>
                    <IonText id='initial_funds' hidden={!initialFundsError} color='danger'>You must select an initial source of funds</IonText>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="12" sizeSm='12' sizeXs="12">
                        <IonLabel>What best describes the ongoing source of funds for this account (check all that apply): *</IonLabel>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_retirement_funds' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_retirement_funds', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Retirement Funds
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_transfer' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_transfer', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Transfer
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_rollover' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_rollover', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Rollover
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_employment_wages' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_employment_wages', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Employment/Wages
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_investments' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_investments', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Investments
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_inheritance_trust' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_inheritance_trust', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Inheritance/Trust
                        </IonRow>
                        <IonRow style={{padding: '4px'}}>
                            <Controller name='ongoing_funds_other' control={control} render={({name, value}) => 
                                <IonCheckbox tabIndex={0} name={name} checked={value} onIonChange={(event)=>{
                                    setValue('ongoing_funds_other', event.detail.checked)
                                }}></IonCheckbox> 
                            }/> &nbsp; Other
                        </IonRow>
                        {ongoing_funds_other && (
                                <>
                                <IonRow>
                                    <IonCol>
                                        <IonItem className={showError('ongoing_funds_other_detail')}>
                                            <Controller name='ongoing_funds_other_detail' control={control} render={({value, name, onBlur, onChange})=>
                                                <IonInput name={name} placeholder='Please describe other ongoing sources of funds:' value={value} onIonBlur={onBlur} onIonInput={onChange}/>
                                            } rules={{required:true, maxLength: 255}} />
                                        </IonItem>
                                        {errors.ongoing_funds_other_detail && <IonText color='danger'>You must enter additional details</IonText>}
                                    </IonCol>
                                </IonRow>
                                </>
                            )}
                    </IonCol>
                    <IonText id='ongoing_funds' hidden={!ongoingFundsError} color='danger'>You must select an ongoing source of funds</IonText>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Household Income</IonLabel>
                        <IonItem>
                            <Controller
                                name={'household_income'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="0-50k">0-50k</IonSelectOption>
                                    <IonSelectOption value="50-100k">50-100k</IonSelectOption>
                                    <IonSelectOption value="100-200k">100-200k</IonSelectOption>
                                    <IonSelectOption value="200-400k">200-400k</IonSelectOption>
                                    <IonSelectOption value="400k+">400k+</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Net Worth</IonLabel>
                        <IonItem>
                            <Controller
                                name={'net_worth'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="0-250k">0-250k</IonSelectOption>
                                    <IonSelectOption value="250-500k">250-500k</IonSelectOption>
                                    <IonSelectOption value="500k-1M">500k-1M</IonSelectOption>
                                    <IonSelectOption value="1M-2M">1M-2M</IonSelectOption>
                                    <IonSelectOption value="2-5M">2-5M</IonSelectOption>
                                    <IonSelectOption value="5M+">5M+</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Projected Retirement</IonLabel>
                        <IonItem>
                            <Controller
                                name={'projected_retirement'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="Currently Retired">Currently Retired</IonSelectOption>
                                    <IonSelectOption value="1-5 Years">1-5 Years</IonSelectOption>
                                    <IonSelectOption value="6-10 Years">6-10 Years</IonSelectOption>
                                    <IonSelectOption value="11-20 Years">11-20 Years</IonSelectOption>
                                    <IonSelectOption value="20+ Years">20+ Years</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Are you Self-Employed? &nbsp;</IonLabel> 
                        <div className="ion-text-wrap">
                            <IonRadioGroup name='is_self_employed' onIonChange={handleIsSelfEmployedChange} value={welcomePageFields.is_self_employed} >
                                <IonLabel>Yes</IonLabel>
                                <IonRadio mode="md" value={true} className='ion-margin-horizontal'/>
                                &nbsp;
                                <IonLabel>No</IonLabel>
                                <IonRadio mode="md" value={false} className='ion-margin-horizontal'/>
                            </IonRadioGroup>
                        </div>               
                    </IonCol> 
                </IonRow>
            </IonGrid>
            </MuiPickersUtilsProvider>                
        </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        welcomePageFields: state.welcomePage,
        is401kApplication: state.menu.menuParams.is401kApplication,
        isDYO401kApplication: state.menu.menuParams.isDYO401kApplication,
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setWelcomePageFields: (payload: any) => dispatch(actions.setWelcomePageFields(payload)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerQuestionnaire);